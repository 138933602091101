import { createWebHistory, createRouter } from "vue-router";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

/*import Home from "@/view/Home.vue";
import About from "@/view/About.vue";
import Login from "@/view/Login.vue";*/

/*const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/about", name: "About", component: About },
  { path: "/login", name: "Login", component: Login },
];*/

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../view/Home.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../view/Login.vue"),
  },
  {
    path: "/login-with-mobile",
    name: "Login With Mobile",
    component: () => import("../view/Login-with-mobile.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../view/Register.vue"),
  },
  {
    path: "/register-confirm",
    name: "Register Confirm",
    component: () => import("../view/RegisterConfirm.vue"),
  },
  {
    path: "/forgotpass",
    name: "ForgotPass",
    component: () => import("../view/ForgotPass.vue"),
  },
  {
    path: "/sale-macbook",
    name: "Sale Macbook",
    component: () => import("../view/Sale-macbook.vue"),
  },
  {
    path: "/sale-macbook-confirm",
    name: "Sale Macbook Confirm",
    component: () => import("../view/Sale-macbook-confirm.vue"),
  },
  {
    path: "/sale-phone",
    name: "Sale Phone",
    component: () => import("../view/Sale-phone.vue"),
  },
  {
    path: "/sale-phone-confirm",
    name: "Sale Phone Confirm",
    component: () => import("../view/Sale-phone-confirm.vue"),
  },
  {
    path: "/all-model",
    name: "All Moddel",
    meta: {
      title: "มือถือที่เรารับซื้อ Apple,Samsung,Huawei,OPPO,Vivo",
    },
    component: () => import("../view/All-model.vue"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("../view/ChangePassword.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../view/Profile.vue"),
  },
  {
    path: "/pick-up-address",
    name: "Pickupaddress",
    component: () => import("../view/Pick-up-address.vue"),
  },
  {
    path: "/sales-list",
    name: "Sales list",
    component: () => import("../view/Sales-list.vue"),
  },
  {
    path: "/promotion",
    name: "promotion",
    meta: {
      title: "Promotion | ขายมือถือกับเราวันนี้ รับฟรี!!ของสมนาคุณ",
    },
    component: () => import("../view/promotion.vue"),
  },
  /*{
    path: "/article",
    name: "article",
    meta: {
      title: "บทความเกี่ยวกับมือถือ,โทรศัพท์ ข่าวสารของโลกไอที",
    },
    component: () => import("../view/article.vue"),
  },*/
  {
    path: "/article",
    children: [
      { path: "", component: () => import("../view/article.vue") },
      {
        path: "/article/:topic",
        component: () => import("../view/article-detail.vue"),
      },
    ],
  },

  {
    path: "/contact-us",
    name: "contact us",
    meta: {
      title: "ติดต่อเราเพื่อขายสินค้า iPhone,iPad,Airpod,samsung galaxy",
    },
    component: () => import("../view/contact-us.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

/*router.beforeEach((to) => {
  document.title =
    to.meta?.title ??
    "Kaitorasap.co.th | ขายโทรศัพท์.com | รับซื้อมือถือ แท็บเล็ต";
});*/

export default router;
