<template>
  <div>
    <!--Navbar-->
    <nav
      class="navbar navbar-fixed-top navbar-dark navbar-expand-lg navbar-kts"
    >
      <div class="container-xl">
        <div class="collapse navbar-collapse show" id="nav-top">
          <div class="container">
            <div class="row">
              <div class="col-5">
                <ul class="navbar-nav p-1 ul-header-menu-l">
                  <li class="nav-item">
                    <a
                      href="/"
                      :title="text_home"
                      class="link header-menu kts-primary-color"
                      >{{ text_home }}</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      :title="text_promotion"
                      href="/promotion"
                      class="link header-menu text-secondary"
                      >{{ text_promotion }}</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      :title="text_we_buy"
                      href="/all-model"
                      class="link header-menu text-secondary"
                      >{{ text_we_buy }}</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      :title="text_article"
                      href="/article"
                      class="link header-menu text-secondary"
                      >{{ text_article }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-2 d-flex justify-content-center">
                <a href="/">
                  <img
                    class="logo-kts-top"
                    src="./assets/logo/logo-kaitorasap-svg.svg"
                  />
                </a>
              </div>
              <div class="col-5">
                <ul class="navbar-nav p-1 ul-header-menu-r">
                  <li class="nav-item">
                    <div class="div-icon-center">
                      <div class="search-box">
                        <input
                          type="text"
                          class="search-input"
                          placeholder="ค้นหา"
                          @input="input_search($event)"
                        />

                        <a class="search-btn">
                          <!-- Seach Icon -->
                          <i class="fas fa-search"></i>
                        </a>
                        <div class="search-list overflow-auto">
                          <ul
                            v-for="model in data_search_list"
                            :key="model.numrow"
                            :value="model.model"
                          >
                            <a
                              v-if="model.type != 'mac'"
                              :href="
                                '/sale-phone?brand=' +
                                model.brand +
                                '&model=' +
                                model.model +
                                '&type=' +
                                model.type +
                                '&model_id=' +
                                model.model_id
                              "
                            >
                              <li>
                                {{ model.model }}
                              </li>
                            </a>

                            <a
                              v-if="model.type == 'mac'"
                              :href="'/sale-macbook?model=' + model.model"
                            >
                              <li>
                                {{ model.model }}
                              </li>
                            </a>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item">
                    <a
                      :class="class_bt_login"
                      class="btn top_btn_login kts-primary-bg-color"
                      href="/login"
                      >{{ text_bt_login }}</a
                    >
                    <div
                      :class="class_customer_dropdown"
                      class="dropdown d-customer-profile"
                    >
                      <p class="p-customer-name" data-bs-toggle="dropdown">
                        {{ p_customer_login_name }}
                      </p>

                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            @click="a_sale_list"
                            href="javascript:void(0)"
                          >
                            <i class="fa fa-mobile"></i>
                            {{ text_sales_history }}</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            @click="a_profile"
                            href="javascript:void(0)"
                          >
                            <i class="fa fa-user-circle"></i>
                            {{ text_profile }}</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            @click="a_pick_up_address"
                            href="javascript:void(0)"
                          >
                            <i class="fa fa-map-marker"></i>
                            {{ text_meeting_point }}</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            @click="a_change_pass"
                            :class="class_bt_change_pass"
                            href="javascript:void(0)"
                          >
                            <i class="fa fa-key"></i>
                            {{ text_change_pass }}</a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item" @click="a_logout" href="#">
                            <i class="fa fa-sign-out"></i>
                            {{ text_log_out }}</a
                          >
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="nav-item">
                    <ul class="select-lag">
                      <li>
                        <a
                          class="link lag"
                          :class="class_lag_en"
                          @click="language_en"
                          >EN</a
                        >
                      </li>
                      <li>|</li>
                      <li>
                        <a
                          class="link lag"
                          :class="class_lag_th"
                          @click="language_th"
                          >TH</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          class="navbar-mobile collapse navbar-collapse"
          id="nav-left-mobile"
        >
          <ul class="navbar-nav bg-light m-0 ml-lg-auto p-5 p-lg-0">
            <!--<li class="d-inline d-lg-none">
              <button
                data-toggle="collapse"
                data-target="#nav"
                class="close float-right"
              >
                &times;
              </button>
            </li>-->
            <li
              class="nav-item li-logo-left-menu-mobile"
              style="text-align: center"
            >
              <img
                class="logo-left-menu-mobile"
                src="./assets/images/logo-kaitorasap-m.png"
              />
            </li>
            <li class="nav-item">
              <div class="menu-setting">
                <div class="login-box">
                  <a
                    class="link-login-register-mobile"
                    href="/login"
                    :title="{ text_bt_login }"
                    >{{ text_bt_login }}</a
                  >
                  <span class="barrier">|</span>
                  <a
                    class="link-login-register-mobile"
                    href="/register"
                    :title="text_register"
                    >{{ text_register }}</a
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="div-top-bar-m">
          <div class="col-sm-5 nav">
            <button
              @click="navbarmobile"
              class="navbar-toggler second-button"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent23"
              aria-controls="navbarSupportedContent23"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div id="navbar-icon" class="animated-icon2">
                <span></span><span></span><span></span><span></span>
              </div>
            </button>
          </div>
          <div class="col-sm-2 text-center">
            <a href="/" class="navbar-logo d-lg-none"
              ><img class="logo-kts-top" src="./assets/logo/logo_mobile.svg"
            /></a>
          </div>
          <div class="col-sm-5">
            <div class="search-box d-lg-none">
              <input
                type="text"
                class="search-input"
                placeholder="ค้นหา"
                @input="input_search($event)"
              />

              <a class="search-btn">
                <!-- Seach Icon -->
                <i class="fas fa-search"></i>
              </a>
              <div class="search-list overflow-auto">
                <ul
                  v-for="model in data_search_list"
                  :key="model.numrow"
                  :value="model.model"
                >
                  <a
                    v-if="model.type != 'mac'"
                    :href="
                      '/sale-phone?brand=' +
                      model.brand +
                      '&model=' +
                      model.model +
                      '&type=' +
                      model.type +
                      '&model_id=' +
                      model.model_id
                    "
                  >
                    <li>
                      {{ model.model }}
                    </li>
                  </a>

                  <a
                    v-if="model.type == 'mac'"
                    :href="'/sale-macbook?model=' + model.model"
                  >
                    <li>
                      {{ model.model }}
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!--/.Navbar-->

    <!-- sidebar -->
    <div class="topbar" :class="class_sidebar">
      <div class="wrap-sidebar">
        <div class="wrap-logo">
          <a href="/">
            <img
              src="https://www.kaitorasap.co.th/images/logo-kaitorasap-m.png"
            />
          </a>
        </div>
        <div class="wrap-lag">
          <ul>
            <li
              @click="language_th"
              class="flag-th-svg"
              :class="class_flag_th"
            ></li>
            <li
              @click="language_en"
              class="flag-en-svg"
              :class="class_flag_en"
            ></li>
          </ul>
        </div>
        <div class="wrap-login">
          <div class="login-box" :class="class_bt_login">
            <a href="/login" class="link header-menu text-secondary">{{
              text_bt_login
            }}</a>
            <span class="barrier">|</span>
            <a href="/register" class="link header-menu text-secondary">{{
              text_register
            }}</a>
          </div>
          <div class="customer-box" :class="class_customer_dropdown">
            <p class="name" data-bs-toggle="dropdown">
              <span>{{ p_hi }}</span> {{ p_customer_login_name }}
            </p>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" href="/sales-list">
                  <i class="fa fa-mobile"></i>
                  {{ text_sales_history }}</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="/profile">
                  <i class="fa fa-user-circle"></i>
                  {{ text_profile }}</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="/pick-up-address">
                  <i class="fa fa-map-marker"></i>
                  {{ text_meeting_point }}</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="/change-password"
                  :class="class_bt_change_pass"
                >
                  <i class="fa fa-key"></i>
                  {{ text_change_pass }}</a
                >
              </li>
              <li>
                <a class="dropdown-item" @click="a_logout" href="#">
                  <i class="fa fa-sign-out"></i>
                  {{ text_log_out }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="wrap-menu">
          <nav class="menu-navbar">
            <ul>
              <li>
                <a
                  href="/"
                  class="active link header-menu text-secondary"
                  :title="text_home"
                  >{{ text_home }}</a
                >
              </li>
              <li>
                <a href="/promotion" class="link header-menu text-secondary">{{
                  text_promotion
                }}</a>
              </li>
              <li>
                <a href="/article" class="link header-menu text-secondary">{{
                  text_article
                }}</a>
              </li>
              <li>
                <a href="/all-model" class="link header-menu text-secondary">{{
                  text_we_buy
                }}</a>
              </li>
              <li>
                <a href="/contact-us" class="link header-menu text-secondary">{{
                  text_contact
                }}</a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="topbar-tabs">
          <div class="topbar-tabs-content">
            <a
              @click="toggle_contact"
              class="topbar-tabs-btn"
              :class="style_contact_active"
            >
              <i class="icon-topbar-tabs icftel"></i>
              <span>{{ text_contact_center }}</span>
            </a>
            <div class="topbar-tab-detail" :class="style_contact">
              <span>{{ text_tel }} : 090-602-0011</span>
              <span>{{ text_email }} : support@kaitorasap.com</span>
              <span>{{ text_mf }} 09.00 - 18.00</span>
              <span>{{ text_ss }} 09:00 - 16:30</span>
            </div>
          </div>
          <div class="topbar-tabs-content">
            <a
              @click="toggle_location"
              class="topbar-tabs-btn"
              :class="style_location_active"
            >
              <i class="icon-topbar-tabs icflocation"></i>
              <span>{{ text_find_center }}</span>
            </a>
            <div class="topbar-tab-detail" :class="style_location">
              <span v-html="text_address"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sidebar -->

    <!-- <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link> -->
    <!--<router-view></router-view> -->

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <VueSocialChat icon class="messenger-chat" :attendants="attendants">
      <template v-slot:header>
        <p>ช่องทางติดต่อสอบถาม</p>
      </template>
      <template v-slot:button>
        <img
          src="https://www.kaitorasap.co.th/images/icon-svg/white_icon.svg"
          alt="icon messenger"
          aria-hidden="true"
        />
      </template>
      <template v-slot:footer>
        <small>
          เวลาทำการ จันทร์ - ศุกร์ 09.00 - 18.00 น. <br />
          เสาร์ - อาทิตย์ 09:00 - 16:30 น.
        </small>
      </template>
    </VueSocialChat>
  </div>
</template>
<script>
import axios from "axios";
//import { th } from "vuejs-datepicker/src/locale";
//import { computed, reactive } from "vue";
//import { useHead } from "@vueuse/head";
export default {
  setup() {
    const attendants = [
      {
        app: "messenger",
        label: "Messenger",
        name: "Kaitorasap",
        id: "1648759048735337",
        avatar: {
          src: "../images/icon-svg/icon-messenger.svg",
          alt: "Kaitorasap",
        },
      },
      {
        app: "line",
        label: "Line Chat",
        name: "Line ID : kaitorasap",
        id: "kaitorasap",
        avatar: {
          src: "../images/icon-svg/icon-line.svg",
          alt: "Kaitorasap",
        },
      },
      {
        app: "whatsapp",
        label: "Hotline",
        name: "090-602-0011",
        id: "0906020011",
        avatar: {
          src: "../images/icon-svg/icon-call.svg",
          alt: "Kaitorasap",
        },
      },
    ];

    return { attendants };
  },
  inject: ["eventBus"],
  name: "App",

  data: function () {
    return {
      language: "TH",
      api_location: {
        link: "https://www.kaitorasap.co.th/api-vue/index.php/",
      },
      class_bt_login: "",
      class_customer_dropdown: "",
      class_bt_change_pass: "",
      p_customer_login_name: "",
      p_hi: "",
      class_lag_en: "",
      class_lag_th: "",
      data_search: "",
      data_search_list: "",

      // *** text **//
      text_home: "",
      text_promotion: "",
      text_we_buy: "",
      text_bt_login: "",
      text_sales_history: "",
      text_profile: "",
      text_meeting_point: "",
      text_change_pass: "",
      text_log_out: "",
      text_register: "",
      text_article: "",
      text_contact: "",
      text_contact_center: "",
      text_find_center: "",
      text_tel: "",
      text_email: "",
      text_mf: "",
      text_ss: "",
      text_address: "",
      // *** end text **//

      isActive_contact: false,
      isActive_location: false,
      isActive_sidebar: false,
      /* class */
      style_contact: "hide",
      style_contact_active: "",

      style_location: "hide",
      style_location_active: "",
      class_sidebar: "",
      class_flag_th: "",
      class_flag_en: "",
      /* class */
      popupActivo: false,
    };
  },

  mounted() {
    /** check lag  */
    this.language = this.$cookies.get("language");
    this.get_language();
    /** check lag  */
    this.get_search();

    // user login //
    if (this.$cookies.get("kts-user") == null) {
      this.class_bt_login = "";
      this.p_customer_login_name = "";
      this.class_customer_dropdown = "d-none";
    } else {
      if (this.$cookies.get("kts-user").name.length > 10) {
        this.p_customer_login_name =
          this.$cookies.get("kts-user").name.substring(0, 10) + "..";
      } else {
        this.p_customer_login_name = this.$cookies.get("kts-user").name;
      }
      this.class_bt_login = "d-none";
      this.class_customer_dropdown = "";
      if (this.$cookies.get("kts-user").type == "email") {
        this.class_bt_change_pass = "";
      } else {
        this.class_bt_change_pass = "d-none";
      }
    }
    // user login //
    if (this.$cookies.get("language") == "en") {
      this.class_lag_en = "kts-primary-color";
      this.class_flag_en = "";
      this.class_flag_th = "flag-dis";
    } else {
      this.class_lag_th = "kts-primary-color";
      this.class_flag_en = "flag-dis";
      this.class_flag_th = "";
    }
  },

  methods: {
    get_language: function () {
      const data = {
        page: "app",
      };
      axios
        .post(this.api_location.link + "language/", data)
        .then((response) => {
          const { status, date_arr } = response.data;
          if (status == true) {
            if (this.language == "en") {
              this.text_home = date_arr[0].en;
              this.text_promotion = date_arr[1].en;
              this.text_we_buy = date_arr[2].en;
              this.text_bt_login = date_arr[3].en;
              this.p_hi = date_arr[4].en + " ";
              this.text_sales_history = date_arr[5].en;
              this.text_profile = date_arr[6].en;
              this.text_meeting_point = date_arr[7].en;
              this.text_change_pass = date_arr[8].en;
              this.text_log_out = date_arr[9].en;
              this.text_register = date_arr[10].en;
              this.text_article = date_arr[11].en;
              this.text_contact = date_arr[12].en;
              this.text_contact_center = date_arr[13].en;
              this.text_find_center = date_arr[14].en;
              this.text_tel = date_arr[15].en;
              this.text_email = date_arr[16].en;
              this.text_mf = date_arr[17].en;
              this.text_ss = date_arr[18].en;
              this.text_address = date_arr[19].en;
            } else {
              this.text_home = date_arr[0].th;
              this.text_promotion = date_arr[1].th;
              this.text_we_buy = date_arr[2].th;
              this.text_bt_login = date_arr[3].th;
              this.p_hi = date_arr[4].th + " ";
              this.text_sales_history = date_arr[5].th;
              this.text_profile = date_arr[6].th;
              this.text_meeting_point = date_arr[7].th;
              this.text_change_pass = date_arr[8].th;
              this.text_log_out = date_arr[9].th;
              this.text_register = date_arr[10].th;
              this.text_article = date_arr[11].th;
              this.text_contact = date_arr[12].th;
              this.text_contact_center = date_arr[13].th;
              this.text_find_center = date_arr[14].th;
              this.text_tel = date_arr[15].th;
              this.text_email = date_arr[16].th;
              this.text_mf = date_arr[17].th;
              this.text_ss = date_arr[18].th;
              this.text_address = date_arr[19].th;
            }
          } else {
            console.log("error");
          }
        });
    },

    get_search: function () {
      axios
        .post(this.api_location.link + "all-model-search/")
        .then((response) => {
          const { date_arr } = response.data;
          this.data_search = date_arr;
          this.data_search_list = date_arr;
        });
    },

    input_search(event) {
      let term = event.target.value;
      this.data_search_list = this.data_search.filter(
        (item) => item.model.toLowerCase().indexOf(term) > -1
      );
    },

    navbarmobile() {
      let element = document.getElementById("navbar-icon");
      element.classList.toggle("open");
      if (this.isActive_sidebar == false) {
        this.isActive_sidebar = true;
        this.class_sidebar = "active";
      } else {
        this.isActive_sidebar = false;
        this.class_sidebar = "";
      }
    },
    a_logout: function () {
      this.$cookies.remove("kts-user");
      window.location.href = "/";
    },
    a_change_pass: function () {
      this.$router.push("/change-password");
    },
    a_profile: function () {
      this.$router.push("/profile");
    },
    a_pick_up_address: function () {
      this.$router.push("/pick-up-address");
    },
    a_sale_list: function () {
      this.$router.push("/sales-list");
    },

    language_en: function () {
      this.language = "en";
      this.$cookies.set("language", "en");
      this.class_lag_en = "kts-primary-color";
      this.class_lag_th = "";
      //emitter.emit("myevent", { a: "b" });
      this.eventBus.emit("lag", "en");
      this.get_language();
      this.class_flag_en = "";
      this.class_flag_th = "flag-dis";
    },
    language_th: function () {
      this.language = "";
      this.$cookies.set("language", "");
      this.class_lag_en = "";
      this.class_lag_th = "kts-primary-color";
      this.eventBus.emit("lag", "");
      this.get_language();
      this.class_flag_en = "flag-dis";
      this.class_flag_th = "";
    },

    toggle_contact: function () {
      if (this.isActive_contact == false) {
        this.isActive_contact = true;
        this.style_contact = "show";
        this.style_contact_active = "active";
      } else {
        this.isActive_contact = false;
        this.style_contact = "hide";
        this.style_contact_active = "";
      }
    },

    toggle_location: function () {
      if (this.isActive_location == false) {
        this.isActive_location = true;
        this.style_location = "show";
        this.style_location_active = "active";
      } else {
        this.isActive_location = false;
        this.style_location = "hide";
        this.style_location_active = "";
      }
    },
  },
};
</script>

<style>
.messenger-chat {
  --messenger: #ff5800;
  --vsc-bg-header: var(--messenger);
  --vsc-bg-button: var(--messenger);
  --vsc-outline-color: var(--messenger);
  font-family: Kanit-Regular;
}
.messenger-chat p {
  font-size: 18px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* navbar show  */

.navbar-toggler {
  color: transparent !important;
}
.animated-icon2 {
  width: 35px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.animated-icon2 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.animated-icon2 span {
  background: #ef5829;
}

.animated-icon2 span:nth-child(1) {
  top: -4px;
}

.animated-icon2 span:nth-child(2),
.animated-icon2 span:nth-child(3) {
  top: 8px;
}

.animated-icon2 span:nth-child(4) {
  top: 20px;
}

.animated-icon2.open span:nth-child(1) {
  top: 11px;
  width: 0%;
  left: 50%;
}

.animated-icon2.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
  top: 11px;
  width: 0%;
  left: 50%;
}

/* navbar show  */

@font-face {
  font-family: "SukhumvitSet-Light";
  src: local("SukhumvitSet-Light"),
    url(./assets/fonts/sukhumvitset/SukhumvitSet-Light.ttf) format("truetype");
}
@font-face {
  font-family: "SukhumvitSet";
  src: local("SukhumvitSet"),
    url(./assets/fonts/sukhumvitset/SukhumvitSet-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "KittithadaMedium";
  src: local("KittithadaMedium"),
    url(./assets/fonts/kittithada/KittithadaMedium65.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit-Light";
  src: local("Kanit-Light"),
    url(./assets/fonts/kanit/Kanit-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit-Regular";
  src: local("Kanit-Regular"),
    url(./assets/fonts/kanit/Kanit-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit-SemiBold";
  src: local("Kanit-SemiBold"),
    url(./assets/fonts/kanit/Kanit-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "CSPraJad_1";
  src: local("CSPraJad_1"),
    url(./assets/fonts/CS_PraJad/CSPraJad_1.ttf) format("truetype");
}
/* use all */

.pen .navbar-toggle {
  border: none;
  background: transparent !important;
}

.pen .navbar-toggle:hover {
  background: transparent !important;
}

.pen .navbar-toggle .icon-bar {
  width: 22px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

/* ANIMATED X */
.navbar-toggle.x .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  -ms-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

.navbar-toggle.x .icon-bar:nth-of-type(2) {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggle.x .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  -ms-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggle.x.collapsed .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(2) {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
/* END ANIMATED X */

.kts-primary-bg-color {
  background-color: #ff5800 !important;
}

.navbar-fixed-top {
  top: 0;
  position: fixed !important;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar-expand-lg {
  background-color: #fff !important;
}

.container-section-home {
  margin-top: 74px;
}

.d-customer-profile .p-customer-name {
  margin: 0;
  cursor: pointer;
  color: #ff5800;
}
.d-customer-profile .p-customer-name span {
  color: #898989;
}
.dropdown-item:hover {
  color: #ff5800 !important;
  transition: all 0.4s;
}

.link {
  cursor: pointer;
}

.show {
  display: block;
}
.hide {
  display: none;
}

.wrap-lag ul {
  padding: 0;
  text-align: center;
  margin-top: 30px;
}
.wrap-lag ul li {
  margin: 0 auto;
  display: inline-block;
}

.flag-th-svg {
  display: block;
  background-image: url(./assets/flag/flag-th.png);
  background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
}

.flag-en-svg {
  display: block;
  background-image: url(./assets/flag/flag-en.png);
  background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
}
.wrap-lag .flag-th-svg,
.flag-en-svg {
  height: 50px;
  width: 50px;
  margin: 0 10px;
  cursor: pointer;
}
.flag-dis {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.6;
}

.search-box {
  font-family: Kanit-Regular;
}

@media (min-width: 1400px) {
  #nav-left-mobile {
    display: none !important;
  }
  .logo-kts-top {
    width: 250px;
  }
  .kts-primary-color {
    color: #ff5800 !important;
  }
  .kts-primary-bg-color {
    background-color: #ff5800 !important;
  }

  .kts-second-color {
    color: #f15719;
  }
  .kts-second-bg-color {
    background-color: #f15719 !important;
  }

  .btn {
    font-family: Kanit-Regular;
  }
  .link:hover {
    color: #f15719 !important;
    transition: color 0.4s ease;
  }
  /* use all */

  /* navbar menu top */
  .navbar-kts {
    font-family: Kanit-Regular;
    background: rgb(228, 228, 228, 0);
    border-bottom: 1px #ccc solid;
    /* background: linear-gradient(
      360deg,
      rgba(228, 228, 228, 1) 0%,
      rgba(221, 221, 221, 1) 2%,
      rgba(233, 233, 233, 1) 4%,
      rgba(255, 255, 255, 1) 64%
    );*/
  }
  .header-menu {
    text-decoration: none;
    font-size: 20px;
  }

  /* ul-header-menu-l  */
  .ul-header-menu-l > li {
    content: "";
    color: #898989;
    padding: 0 15px 0 15px;
    line-height: 48px;
  }
  .ul-header-menu-l li .link:hover,
  .ul-header-menu-l li .link.router-link-active,
  .ul-header-menu-l li .link.router-link-exact-active {
    color: #ff5800 !important ;
    transition: color 0.4s ease;
  }
  .ul-header-menu-l li .link {
    transition: color 0.4s ease;
  }
  /* end ul-header-menu-l  */

  /* ul-header-menu-r */

  .ul-header-menu-r {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 0;
    text-align: center;
    line-height: 48px;
  }

  .ul-header-menu-r .lag-select {
    background-color: #ff5800 !important;
    opacity: 0.9;
  }
  .ul-header-menu-r .top_btn_login {
    font-size: 16px !important;
    color: #fff !important;
  }
  .ul-header-menu-r .div-icon-center {
    display: block;
    margin-right: 30px;
    height: 48px;
    line-height: 53px;
  }
  .ul-header-menu-r .div-icon-center .top-find-model {
    padding: 0 70px 0 0;
    font-size: 20px;
    color: #898989;
    cursor: pointer;
    transition: color 0.4s ease;
  }
  .ul-header-menu-r .top-find-model:hover {
    color: #ff5800;
    transition: color 0.4s ease;
  }
  .ul-header-menu-r .select-lag li {
    display: inline-block;
    padding: 0 3px 0 3px;
    font-size: 16px;
  }
  .ul-header-menu-r li .lag {
    color: #898989;
    text-decoration: none;
  }
  .topbar {
    display: none;
  }

  /* search */
  .navbar-nav .nav-item .div-icon-center {
    margin-right: 165px;
  }
  .search-box {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ff9f6e;
    height: 45px;
    border-radius: 50px;
    padding: 5px;
  }
  /* input */
  .search-input {
    outline: none;
    border: none;
    background: none;
    width: 0;
    padding: 0;
    color: #fff;
    float: left;
    font-size: 18px;
    transition: 0.3s;
    line-height: 35px;
  }
  .search-input::placeholder {
    color: #fff;
  }
  /* icon */
  .search-btn {
    color: #fff;
    float: right;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background: #ff5800;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
  }
  .search-input:focus,
  .search-input:not(:placeholder-shown) {
    width: 240px;
    padding: 0 10px;
  }
  .search-box:hover > .search-input {
    width: 240px;
    padding: 0 10px;
  }

  .search-box:hover > .search-btn,
  .search-input:focus + .search-btn,
  .search-input:not(:placeholder-shown) + .search-btn {
    background: #fff;
    color: #cd595a;
  }
  .search-list {
    display: none;
    margin-top: 50px;
    position: absolute;
    width: 350px;
    max-height: 350px;
    padding: 10px;
    background-color: #fff;
    left: -8%;
    border: 1px #ccc solid;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
    transition: 1s;
  }
  .search-box:hover ~ .search-list {
    display: block;
  }
  .search-input:focus ~ .search-list {
    display: block;
  }
  .search-list:hover {
    display: block;
  }
  .search-list ul {
    padding: 0;
  }
  .search-list ul li {
    list-style: none;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
  }
  .search-list ul li:hover {
    background-color: #ffcaaf;
    transition: 0.3s;
  }
  .search-list ul a {
    text-decoration: none;
    color: #515150;
  }
  .search-list ul a:hover {
    text-decoration: none;
    color: #000;
  }
  /** end search */
} /**1400px */

@media (min-width: 1200px) and (max-width: 1399.98px) {
  #nav-left-mobile {
    display: none !important;
  }
  .logo-kts-top {
    width: 225px;
    margin-top: 7px;
  }
  .kts-primary-color {
    color: #ff5800 !important;
  }
  .kts-primary-bg-color {
    background-color: #ff5800 !important;
  }

  .kts-second-color {
    color: #f15719;
  }
  .kts-second-bg-color {
    background-color: #f15719 !important;
  }

  .btn {
    font-family: Kanit-Regular;
  }
  .link:hover {
    color: #f15719 !important;
    transition: color 0.4s ease;
  }
  /* use all */

  /* navbar menu top */
  .navbar-kts {
    font-family: Kanit-Regular;
    background: rgb(228, 228, 228, 0);
    border-bottom: 1px #ccc solid;
    /*background: linear-gradient(
      360deg,
      rgba(228, 228, 228, 1) 0%,
      rgba(221, 221, 221, 1) 2%,
      rgba(233, 233, 233, 1) 4%,
      rgba(255, 255, 255, 1) 64%
    );*/
  }
  .header-menu {
    text-decoration: none;
    font-size: 17px;
  }

  /* ul-header-menu-l  */
  .ul-header-menu-l > li {
    content: "";
    color: #898989;
    padding: 0 15px 0 15px;
    line-height: 48px;
  }
  .ul-header-menu-l li .link:hover,
  .ul-header-menu-l li .link.router-link-active,
  .ul-header-menu-l li .link.router-link-exact-active {
    color: #ff5800 !important ;
    transition: color 0.4s ease;
  }
  .ul-header-menu-l li .link {
    transition: color 0.4s ease;
  }
  /* end ul-header-menu-l  */

  /* ul-header-menu-r */

  .ul-header-menu-r {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 0;
    text-align: center;
    line-height: 48px;
  }

  .ul-header-menu-r .lag-select {
    background-color: #ff5800 !important;
    opacity: 0.9;
  }
  .ul-header-menu-r .top_btn_login {
    font-size: 14px !important;
    color: #fff !important;
  }
  .ul-header-menu-r .div-icon-center {
    display: block;
    margin-right: 30px;
    height: 48px;
    line-height: 53px;
  }
  .ul-header-menu-r .div-icon-center .top-find-model {
    padding: 0 70px 0 0;
    font-size: 17px;
    color: #898989;
    cursor: pointer;
    transition: color 0.4s ease;
  }
  .ul-header-menu-r .top-find-model:hover {
    color: #ff5800;
    transition: color 0.4s ease;
  }
  .ul-header-menu-r .select-lag li {
    display: inline-block;
    padding: 0 3px 0 3px;
    font-size: 14px;
  }
  .ul-header-menu-r li .lag {
    color: #898989;
    text-decoration: none;
  }
  .topbar {
    display: none;
  }

  /* search */
  .navbar-nav .nav-item .div-icon-center {
    margin-right: 133px;
  }

  .search-box {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ff9f6e;
    height: 37px;
    border-radius: 50px;
    padding: 4px;
  }
  /* input */
  .search-input {
    outline: none;
    border: none;
    background: none;
    width: 0;
    padding: 0;
    color: #fff;
    float: left;
    font-size: 16px;
    transition: 0.3s;
    line-height: 28px;
  }
  .search-input::placeholder {
    color: #fff;
  }
  /* icon */
  .search-btn {
    color: #fff;
    float: right;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #ff5800;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
  }
  .search-input:focus,
  .search-input:not(:placeholder-shown) {
    width: 200px;
    padding: 0 10px;
  }
  .search-box:hover > .search-input {
    width: 200px;
    padding: 0 10px;
  }

  .search-box:hover > .search-btn,
  .search-input:focus + .search-btn,
  .search-input:not(:placeholder-shown) + .search-btn {
    background: #fff;
    color: #cd595a;
  }
  .search-list {
    display: none;
    margin-top: 50px;
    position: absolute;
    width: 350px;
    max-height: 350px;
    padding: 10px;
    background-color: #fff;
    left: -8%;
    border: 1px #ccc solid;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
  }
  .search-box:hover ~ .search-list {
    display: block;
  }
  .search-input:focus ~ .search-list {
    display: block;
  }
  .search-list:hover {
    display: block;
  }

  .search-list ul {
    padding: 0;
  }
  .search-list ul li {
    list-style: none;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
  }
  .search-list ul li:hover {
    background-color: #ffcaaf;
    transition: 0.3s;
  }
  .search-list ul a {
    text-decoration: none;
    color: #515150;
  }
  .search-list ul a:hover {
    text-decoration: none;
    color: #000;
  }
  /** end search */
} /**1200px */

@media (min-width: 992px) and (max-width: 1199.98px) {
  #nav-left-mobile {
    display: none !important;
  }
  .logo-kts-top {
    width: 200px;
    margin-top: 10px;
  }
  .kts-primary-color {
    color: #ff5800 !important;
  }
  .kts-primary-bg-color {
    background-color: #ff5800 !important;
  }

  .kts-second-color {
    color: #f15719;
  }
  .kts-second-bg-color {
    background-color: #f15719 !important;
  }

  .btn {
    font-family: Kanit-Regular;
  }
  .link:hover {
    color: #f15719 !important;
    transition: color 0.4s ease;
  }
  /* use all */

  /* navbar menu top */
  .navbar-kts {
    font-family: Kanit-Regular;
    background: rgb(228, 228, 228, 0);
    border-bottom: 1px #ccc solid;
    /*background: linear-gradient(
      360deg,
      rgba(228, 228, 228, 1) 0%,
      rgba(221, 221, 221, 1) 2%,
      rgba(233, 233, 233, 1) 4%,
      rgba(255, 255, 255, 1) 64%
    );*/
  }
  .header-menu {
    text-decoration: none;
    font-size: 15px;
  }

  /* ul-header-menu-l  */
  .ul-header-menu-l > li {
    content: "";
    color: #898989;
    padding: 0 12px 0 12px;
    line-height: 48px;
  }
  .ul-header-menu-l li .link:hover,
  .ul-header-menu-l li .link.router-link-active,
  .ul-header-menu-l li .link.router-link-exact-active {
    color: #ff5800 !important ;
    transition: color 0.4s ease;
  }
  .ul-header-menu-l li .link {
    transition: color 0.4s ease;
  }
  /* end ul-header-menu-l  */

  /* ul-header-menu-r */

  .ul-header-menu-r {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 0;
    text-align: center;
    line-height: 48px;
  }

  .ul-header-menu-r .lag-select {
    background-color: #ff5800 !important;
    opacity: 0.9;
  }
  .ul-header-menu-r .top_btn_login {
    font-size: 12px !important;
    color: #fff !important;
  }
  .ul-header-menu-r .div-icon-center {
    display: block;
    margin-right: 30px;
    height: 48px;
    line-height: 53px;
  }
  .ul-header-menu-r .div-icon-center .top-find-model {
    padding: 0 70px 0 0;
    font-size: 14px;
    color: #898989;
    cursor: pointer;
    transition: color 0.4s ease;
  }
  .ul-header-menu-r .top-find-model:hover {
    color: #ff5800;
    transition: color 0.4s ease;
  }
  .ul-header-menu-r .select-lag li {
    display: inline-block;
    padding: 0 3px 0 3px;
    font-size: 12px;
  }
  .ul-header-menu-r li .lag {
    color: #898989;
    text-decoration: none;
  }
  .topbar {
    display: none;
  }

  /* search */
  .navbar-nav .nav-item .div-icon-center {
    margin-right: 106px;
  }

  .search-box {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ff9f6e;
    height: 29px;
    border-radius: 50px;
    padding: 2px;
  }
  /* input */
  .search-input {
    outline: none;
    border: none;
    background: none;
    width: 0;
    padding: 0;
    color: #fff;
    float: left;
    font-size: 14px;
    transition: 0.3s;
    line-height: 27px;
  }
  .search-input::placeholder {
    color: #fff;
  }
  /* icon */
  .search-btn {
    color: #fff;
    float: right;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background: #ff5800;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
  }
  .search-input:focus,
  .search-input:not(:placeholder-shown) {
    width: 160px;
    padding: 0 10px;
  }
  .search-box:hover > .search-input {
    width: 160px;
    padding: 0 10px;
  }

  .search-box:hover > .search-btn,
  .search-input:focus + .search-btn,
  .search-input:not(:placeholder-shown) + .search-btn {
    background: #fff;
    color: #cd595a;
  }
  .search-list {
    display: none;
    margin-top: 50px;
    position: absolute;
    width: 350px;
    max-height: 350px;
    padding: 10px;
    background-color: #fff;
    left: -8%;
    border: 1px #ccc solid;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
  }
  .search-box:hover ~ .search-list {
    display: block;
  }
  .search-input:focus ~ .search-list {
    display: block;
  }
  .search-list:hover {
    display: block;
  }

  .search-list ul {
    padding: 0;
  }
  .search-list ul li {
    list-style: none;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
  }
  .search-list ul li:hover {
    background-color: #ffcaaf;
    transition: 0.3s;
  }
  .search-list ul a {
    text-decoration: none;
    color: #515150;
  }
  .search-list ul a:hover {
    text-decoration: none;
    color: #000;
  }
  /** end search */
} /**992px */

@media (min-width: 768px) and (max-width: 991.98px) {
  #nav-top {
    display: none !important;
  }
  .logo-kts-top {
    width: 55px;
  }
  .top-find-model-mobile {
    color: #898989;
    font-size: 24px;
  }
  .navbar-collapse.collapsing .navbar-nav {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -45%;
    transition: all 0.2s ease;
  }

  .navbar-collapse.show .navbar-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    height: auto;
    width: 55%;
    transition: left 0.35s ease;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 999;
  }
  .navbar-mobile ul li {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding: 0 16px;
  }
  .navbar-mobile ul li + li + li {
    border-top: 2px solid #787878;
  }

  .li-logo-left-menu-mobile {
    margin: 10px 0 20px 0;
  }

  .logo-left-menu-mobile {
    width: 250px;
  }

  .navbar-mobile ul li .left-menu-mobile {
    text-decoration: none;
    font-size: 26px;
    color: #4a4a4a;
  }

  .menu-setting {
    top: 144px;
  }

  .menu-setting .link-login-register-mobile {
    font-size: 28px;
    text-decoration: none;
    color: #4a4a4a;
  }
  .menu-setting .barrier {
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .login-box::before {
    top: 12px;
    width: 38px;
    height: 38px;
    margin-right: 7px;
  }
  .login-box::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 18px;
    width: 50px;
    height: 50px;
    margin-right: 18px;
    background-image: url(./assets/images/icon-login-topbar.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
  }
  .customer-box .name {
    font-family: Kanit-Regular;
    text-align: center;
    color: #ff5800;
    font-size: 24px;
  }
  .customer-box .name span {
    color: #4a4a4a;
  }

  .navbar-kts {
    border-bottom: 1px #ccc solid;
  }

  /* sidebar */
  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .topbar.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .wrap-sidebar {
    position: fixed;
    width: 500px;
    height: 100%;
    background-color: #fff;
    z-index: 500;
    overflow: auto;
  }
  .wrap-sidebar .wrap-logo img {
    width: 60%;
    display: block;
    margin: 0 auto;
    margin-top: 100px;
  }

  .wrap-sidebar .wrap-login .login-box {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 5px;
    text-align: center;
  }

  .wrap-sidebar .wrap-login .login-box::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 18px;
    width: 57px;
    height: 57px;
    margin-right: 18px;
    background-image: url(./assets/images/icon-login-topbar.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
  }

  .wrap-sidebar .wrap-login .login-box a,
  .login-box .barrier {
    display: inline-block;
    font-family: Kanit-Regular;
    font-size: 30px;
    color: #4a4a4a;
    font-weight: normal;
    line-height: 1;
    text-decoration: none;
    margin: 0px 6px;
  }
  .wrap-sidebar .wrap-menu {
    padding: 45px;
  }
  .wrap-sidebar .wrap-menu .menu-navbar ul {
    display: inline-block;
    width: 100%;
    padding: 0;
  }
  .wrap-sidebar .wrap-menu .menu-navbar ul li {
    width: 100%;
    height: 106px;
    font-size: 30px;
    line-height: 104px;
    padding: 0 16px;
    border-top: 2px solid #787878;
    list-style: none;
  }
  .wrap-sidebar .wrap-menu .menu-navbar ul li a {
    display: inline-block;
    font-family: "Kanit-Regular";
    color: #4a4a4a;
    font-weight: normal;
    line-height: 1;
    text-decoration: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .wrap-sidebar .topbar-tabs {
    float: left;
    width: 100%;
  }
  .wrap-sidebar .topbar-tabs-content {
    display: block;
    float: left;
    width: 100%;
    background-color: #f4f2f2;
  }
  .wrap-sidebar .topbar-tabs-btn {
    display: block;
    float: left;
    width: 100%;
    height: 102px;
    font-family: Kanit-Regular;
    font-size: 30px;
    color: #fff;
    font-weight: normal;
    line-height: 102px;
    padding: 0 56px 0 45px;
    margin-bottom: 2px;
    cursor: pointer;
    background-color: #595858;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    text-decoration: none;
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs.icftel {
    background-image: url(./assets/images/icon-contact.png);
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs {
    display: inline-block;
    float: left;
    width: 58px;
    height: 58px;
    margin-top: 23px;
    margin-right: 23px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs.icflocation {
    background-image: url(./assets/images/icon-location.png);
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs {
    display: inline-block;
    float: left;
    width: 58px;
    height: 58px;
    margin-top: 23px;
    margin-right: 23px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
  .wrap-sidebar .topbar-tabs-btn::after {
    content: "";
    display: inline-block;
    float: right;
    width: 28px;
    height: 28px;
    border: 1px solid #fff;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-width: 0 5px 5px 0;
    margin-top: 30px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .wrap-sidebar .topbar-tab-detail {
    float: left;
    width: 100%;
    padding: 37px 58px;
  }
  .wrap-sidebar .topbar-tab-detail span {
    display: block;
    float: left;
    width: 100%;
    font-family: Kanit-Regular;
    font-size: 24px;
    color: #787878;
    font-weight: normal;
    line-height: 1.6;
  }

  .wrap-sidebar .topbar-tabs-btn.active::after {
    margin-top: 44px;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  /* sidebar */

  .div-top-bar-m {
    width: 100%;
    display: flex;
  }
  .div-top-bar-m .nav {
    display: inline-flex;
  }
  .navbar-toggler {
    padding: 0 !important;
  }

  /** search */
  .search-box {
    background: #ff9f6e;
    height: 44px;
    border-radius: 50px;
    padding: 2px;
    float: right;
    margin-top: 7px;
  }
  /* input */
  .search-input {
    outline: none;
    border: none;
    background: none;
    width: 0;
    padding: 0;
    color: #fff;
    float: left;
    font-size: 21px;
    transition: 0.3s;
    line-height: 38px;
  }
  .search-input::placeholder {
    color: #fff;
  }
  /* icon */
  .search-btn {
    color: #fff;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: #ff5800;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
  }
  .search-input:focus,
  .search-input:not(:placeholder-shown) {
    width: 200px;
    padding: 0 10px;
  }
  .search-box:hover > .search-input {
    width: 200px;
    padding: 0 10px;
  }

  .search-box:hover > .search-btn,
  .search-input:focus + .search-btn,
  .search-input:not(:placeholder-shown) + .search-btn {
    background: #fff;
    color: #cd595a;
  }
  .search-list {
    display: none;
    margin-top: 50px;
    position: absolute;
    width: 350px;
    max-height: 350px;
    padding: 10px;
    background-color: #fff;
    right: 2%;
    border: 1px #ccc solid;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
  }
  .search-box:hover ~ .search-list {
    display: block;
  }
  .search-input:focus ~ .search-list {
    display: block;
  }
  .search-list:hover {
    display: block;
  }
  .search-list ul {
    padding: 0;
    margin-bottom: 5px;
  }
  .search-list ul li {
    list-style: none;
    font-size: 18px;
    transition: 0.3s;
    padding: 5px 5px;
    cursor: pointer;
  }
  .search-list ul li:hover {
    background-color: #ffcaaf;
    transition: 0.3s;
  }
  .search-list ul a {
    text-decoration: none;
    color: #515150;
  }
  .search-list ul a:hover {
    text-decoration: none;
    color: #000;
  }
  .dropdown-menu {
    font-family: Kanit-Regular;
    inset: 0px auto auto 30% !important;
  }
  .dropdown-menu li a {
    font-size: 24px;
    padding: 10px;
  }

  /** end search */
} /**768px */

@media (min-width: 576px) and (max-width: 767.98px) {
  #nav-top {
    display: none !important;
  }
  .logo-kts-top {
    width: 55px;
  }
  .top-find-model-mobile {
    color: #898989;
    font-size: 24px;
  }
  .navbar-collapse.collapsing .navbar-nav {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -45%;
    transition: all 0.2s ease;
  }

  .navbar-collapse.show .navbar-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    height: auto;
    width: 55%;
    transition: left 0.35s ease;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 999;
  }
  .navbar-mobile ul li {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding: 0 16px;
  }
  .navbar-mobile ul li + li + li {
    border-top: 2px solid #787878;
  }

  .li-logo-left-menu-mobile {
    margin: 10px 0 20px 0;
  }

  .logo-left-menu-mobile {
    width: 250px;
  }

  .navbar-mobile ul li .left-menu-mobile {
    text-decoration: none;
    font-size: 26px;
    color: #4a4a4a;
  }

  .menu-setting {
    top: 144px;
  }

  .menu-setting .link-login-register-mobile {
    font-size: 28px;
    text-decoration: none;
    color: #4a4a4a;
  }
  .menu-setting .barrier {
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .login-box::before {
    top: 12px;
    width: 38px;
    height: 38px;
    margin-right: 7px;
  }
  .login-box::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 18px;
    width: 50px;
    height: 50px;
    margin-right: 18px;
    background-image: url(./assets/images/icon-login-topbar.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
  }
  .customer-box .name {
    font-family: Kanit-Regular;
    text-align: center;
    color: #ff5800;
    font-size: 24px;
  }
  .customer-box .name span {
    color: #4a4a4a;
  }
  .navbar-kts {
    border-bottom: 1px #ccc solid;
  }

  /* sidebar */
  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .topbar.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .wrap-sidebar {
    position: fixed;
    width: 500px;
    height: 100%;
    background-color: #fff;
    z-index: 500;
    overflow: auto;
  }
  .wrap-sidebar .wrap-logo img {
    width: 60%;
    display: block;
    margin: 0 auto;
    margin-top: 100px;
  }

  .wrap-sidebar .wrap-login .login-box {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 5px;
    text-align: center;
  }

  .wrap-sidebar .wrap-login .login-box::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 18px;
    width: 57px;
    height: 57px;
    margin-right: 18px;
    background-image: url(./assets/images/icon-login-topbar.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
  }

  .wrap-sidebar .wrap-login .login-box a,
  .login-box .barrier {
    display: inline-block;
    font-family: Kanit-Regular;
    font-size: 30px;
    color: #4a4a4a;
    font-weight: normal;
    line-height: 1;
    text-decoration: none;
    margin: 0px 6px;
  }
  .wrap-sidebar .wrap-menu {
    padding: 45px;
  }
  .wrap-sidebar .wrap-menu .menu-navbar ul {
    display: inline-block;
    width: 100%;
    padding: 0;
  }
  .wrap-sidebar .wrap-menu .menu-navbar ul li {
    width: 100%;
    height: 106px;
    font-size: 30px;
    line-height: 104px;
    padding: 0 16px;
    border-top: 2px solid #787878;
    list-style: none;
  }
  .wrap-sidebar .wrap-menu .menu-navbar ul li a {
    display: inline-block;
    font-family: "Kanit-Regular";
    color: #4a4a4a;
    font-weight: normal;
    line-height: 1;
    text-decoration: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .wrap-sidebar .topbar-tabs {
    float: left;
    width: 100%;
  }
  .wrap-sidebar .topbar-tabs-content {
    display: block;
    float: left;
    width: 100%;
    background-color: #f4f2f2;
  }
  .wrap-sidebar .topbar-tabs-btn {
    display: block;
    float: left;
    width: 100%;
    height: 102px;
    font-family: Kanit-Regular;
    font-size: 30px;
    color: #fff;
    font-weight: normal;
    line-height: 102px;
    padding: 0 56px 0 45px;
    margin-bottom: 2px;
    cursor: pointer;
    background-color: #595858;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    text-decoration: none;
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs.icftel {
    background-image: url(./assets/images/icon-contact.png);
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs {
    display: inline-block;
    float: left;
    width: 58px;
    height: 58px;
    margin-top: 23px;
    margin-right: 23px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs.icflocation {
    background-image: url(./assets/images/icon-location.png);
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs {
    display: inline-block;
    float: left;
    width: 58px;
    height: 58px;
    margin-top: 23px;
    margin-right: 23px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
  .wrap-sidebar .topbar-tabs-btn::after {
    content: "";
    display: inline-block;
    float: right;
    width: 28px;
    height: 28px;
    border: 1px solid #fff;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-width: 0 5px 5px 0;
    margin-top: 30px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .wrap-sidebar .topbar-tab-detail {
    float: left;
    width: 100%;
    padding: 37px 58px;
  }
  .wrap-sidebar .topbar-tab-detail span {
    display: block;
    float: left;
    width: 100%;
    font-family: Kanit-Regular;
    font-size: 24px;
    color: #787878;
    font-weight: normal;
    line-height: 1.6;
  }

  .wrap-sidebar .topbar-tabs-btn.active::after {
    margin-top: 44px;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    transform: rotate(225deg);
  }

  /* sidebar */

  .div-top-bar-m {
    width: 100%;
    display: flex;
  }
  .div-top-bar-m .nav {
    display: inline-flex;
  }
  .navbar-toggler {
    padding: 0 !important;
  }

  /** search */
  .search-box {
    background: #ff9f6e;
    height: 44px;
    border-radius: 50px;
    padding: 2px;
    float: right;
    margin-top: 7px;
  }
  /* input */
  .search-input {
    outline: none;
    border: none;
    background: none;
    width: 0;
    padding: 0;
    color: #fff;
    float: left;
    font-size: 21px;
    transition: 0.3s;
    line-height: 38px;
  }
  .search-input::placeholder {
    color: #fff;
  }
  /* icon */
  .search-btn {
    color: #fff;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: #ff5800;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
  }
  .search-input:focus,
  .search-input:not(:placeholder-shown) {
    width: 180px;
    padding: 0 10px;
  }
  .search-box:hover > .search-input {
    width: 180px;
    padding: 0 10px;
  }

  .search-box:hover > .search-btn,
  .search-input:focus + .search-btn,
  .search-input:not(:placeholder-shown) + .search-btn {
    background: #fff;
    color: #cd595a;
  }
  .search-list {
    display: none;
    margin-top: 50px;
    position: absolute;
    width: 350px;
    max-height: 350px;
    padding: 10px;
    background-color: #fff;
    right: 2%;
    border: 1px #ccc solid;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
  }
  .search-box:hover ~ .search-list {
    display: block;
  }
  .search-input:focus ~ .search-list {
    display: block;
  }
  .search-list:hover {
    display: block;
  }
  .search-list ul {
    padding: 0;
    margin-bottom: 5px;
  }
  .search-list ul li {
    list-style: none;
    font-size: 18px;
    transition: 0.3s;
    padding: 5px 5px;
    cursor: pointer;
  }
  .search-list ul li:hover {
    background-color: #ffcaaf;
    transition: 0.3s;
  }
  .search-list ul a {
    text-decoration: none;
    color: #515150;
  }
  .search-list ul a:hover {
    text-decoration: none;
    color: #000;
  }

  .dropdown-menu {
    font-family: Kanit-Regular;
    inset: 0px auto auto 30% !important;
  }
  .dropdown-menu li a {
    font-size: 24px;
    padding: 10px;
  }
  /** end search */
} /**576px */

@media (max-width: 575.98px) {
  .container-section-home {
    margin-top: 65px;
  }
  #nav-top {
    display: none !important;
  }
  .logo-kts-top {
    width: 45px;
  }
  .top-find-model-mobile {
    color: #898989;
    font-size: 24px;
  }
  .navbar-collapse.collapsing .navbar-nav {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -45%;
    transition: all 0.2s ease;
  }

  .navbar-collapse.show .navbar-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    height: auto;
    width: 55%;
    transition: left 0.35s ease;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 999;
  }
  .navbar-mobile ul li {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding: 0 16px;
  }
  .navbar-mobile ul li + li + li {
    border-top: 2px solid #787878;
  }

  .li-logo-left-menu-mobile {
    margin: 10px 0 20px 0;
  }

  .logo-left-menu-mobile {
    width: 250px;
  }

  .navbar-mobile ul li .left-menu-mobile {
    text-decoration: none;
    font-size: 26px;
    color: #4a4a4a;
  }

  .menu-setting {
    top: 144px;
  }

  .menu-setting .link-login-register-mobile {
    font-size: 28px;
    text-decoration: none;
    color: #4a4a4a;
  }
  .menu-setting .barrier {
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .login-box::before {
    top: 12px;
    width: 38px;
    height: 38px;
    margin-right: 7px;
  }
  .login-box::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 18px;
    width: 50px;
    height: 50px;
    margin-right: 18px;
    background-image: url(./assets/images/icon-login-topbar.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
  }
  .customer-box .name {
    font-family: Kanit-Regular;
    text-align: center;
    color: #ff5800;
    font-size: 24px;
  }
  .customer-box .name span {
    color: #4a4a4a;
  }
  .navbar-kts {
    border-bottom: 1px #ccc solid;
  }

  /* sidebar */
  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .topbar.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .wrap-sidebar {
    position: fixed;
    width: 90%;
    height: 100%;
    background-color: #fff;
    z-index: 500;
    overflow: auto;
  }
  .wrap-sidebar .wrap-logo img {
    width: 60%;
    display: block;
    margin: 0 auto;
    margin-top: 100px;
  }

  .wrap-sidebar .wrap-login .login-box {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 5px;
    text-align: center;
  }

  .wrap-sidebar .wrap-login .login-box::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 13px;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    background-image: url(./assets/images/icon-login-topbar.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
  }

  .wrap-sidebar .wrap-login .login-box a,
  .login-box .barrier {
    display: inline-block;
    font-family: Kanit-Regular;
    font-size: 20px;
    color: #4a4a4a;
    font-weight: normal;
    line-height: 1;
    text-decoration: none;
    margin: 0px 4px;
  }
  .wrap-sidebar .wrap-menu {
    margin-top: 0px;
    padding: 30px;
  }
  .wrap-sidebar .wrap-menu .menu-navbar ul {
    display: inline-block;
    width: 100%;
    padding: 0;
  }
  .wrap-sidebar .wrap-menu .menu-navbar ul li {
    width: 100%;
    height: 78px;
    font-size: 22.5px;
    line-height: 73px;
    padding: 0 16px;
    border-top: 2px solid #787878;
    list-style: none;
  }
  .wrap-sidebar .wrap-menu .menu-navbar ul li a {
    display: inline-block;
    font-family: "Kanit-Regular";
    color: #4a4a4a;
    font-weight: normal;
    line-height: 1;
    text-decoration: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .wrap-sidebar .topbar-tabs {
    float: left;
    width: 100%;
  }
  .wrap-sidebar .topbar-tabs-content {
    display: block;
    float: left;
    width: 100%;
    background-color: #f4f2f2;
  }
  .wrap-sidebar .topbar-tabs-btn {
    display: block;
    float: left;
    width: 100%;
    height: 100px;
    font-family: Kanit-Regular;
    font-size: 24px;
    color: #fff;
    font-weight: normal;
    line-height: 102px;
    padding: 0 20px 0 15px;
    margin-bottom: 2px;
    cursor: pointer;
    background-color: #595858;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    text-decoration: none;
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs.icftel {
    background-image: url(./assets/images/icon-contact.png);
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs {
    display: inline-block;
    float: left;
    width: 40px;
    height: 45px;
    margin-top: 29px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
  .wrap-sidebar .topbar-tabs-btn .icon-topbar-tabs.icflocation {
    background-image: url(./assets/images/icon-location.png);
  }

  .wrap-sidebar .topbar-tabs-btn::after {
    content: "";
    display: inline-block;
    float: right;
    width: 18px;
    height: 18px;
    border: 1px solid #fff;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-width: 0 3px 3px 0;
    margin-top: 40px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .wrap-sidebar .topbar-tab-detail {
    float: left;
    width: 100%;
    padding: 25px 37px;
  }
  .wrap-sidebar .topbar-tab-detail span {
    display: block;
    float: left;
    width: 100%;
    font-family: Kanit-Regular;
    font-size: 17px;
    color: #787878;
    font-weight: normal;
    line-height: 1.6;
  }

  .wrap-sidebar .topbar-tabs-btn.active::after {
    margin-top: 44px;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    transform: rotate(225deg);
  }

  /* sidebar */

  .div-top-bar-m {
    width: 100%;
    display: flex;
  }
  .div-top-bar-m .nav {
    display: inline-flex;
  }
  .navbar-toggler {
    padding: 0 !important;
  }

  .div-top-bar-m .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .div-top-bar-m .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  /** search */
  .search-box {
    background: #ff9f6e;
    height: 39px;
    border-radius: 50px;
    padding: 2px;
    float: right;
    margin-top: 3px;
  }
  /* input */
  .search-input {
    outline: none;
    border: none;
    background: none;
    width: 0;
    padding: 0;
    color: #fff;
    float: left;
    font-size: 17px;
    transition: 0.3s;
    line-height: 34px;
  }
  .search-input::placeholder {
    color: #fff;
  }
  /* icon */
  .search-btn {
    color: #fff;
    float: right;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background: #ff5800;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
  }
  .search-input:focus,
  .search-input:not(:placeholder-shown) {
    width: 95px;
    padding: 0 10px;
  }
  .search-box:hover > .search-input {
    width: 95px;
    padding: 0 10px;
  }

  .search-box:hover > .search-btn,
  .search-input:focus + .search-btn,
  .search-input:not(:placeholder-shown) + .search-btn {
    background: #fff;
    color: #cd595a;
  }
  .search-list {
    display: none;
    margin-top: 50px;
    position: absolute;
    width: 350px;
    max-height: 350px;
    padding: 10px;
    background-color: #fff;
    right: 2%;
    border: 1px #ccc solid;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
  }
  .search-box:hover ~ .search-list {
    display: block;
  }
  .search-input:focus ~ .search-list {
    display: block;
  }
  .search-list:hover {
    display: block;
  }
  .search-list ul {
    padding: 0;
    margin-bottom: 5px;
  }
  .search-list ul li {
    list-style: none;
    font-size: 18px;
    transition: 0.3s;
    padding: 5px 5px;
    cursor: pointer;
  }
  .search-list ul li:hover {
    background-color: #ffcaaf;
    transition: 0.3s;
  }
  .search-list ul a {
    text-decoration: none;
    color: #515150;
  }
  .search-list ul a:hover {
    text-decoration: none;
    color: #000;
  }
  /** end search */
  .dropdown-menu {
    font-family: Kanit-Regular;
    inset: 0px auto auto 15% !important;
  }
  .dropdown-menu li a {
    font-size: 24px;
    padding: 10px;
  }
} /**575.98px */

/* navbar menu top */
@import "assets/sweetalert2/sweetalert2.css";
</style>
